.fc {
  height: 100%
}

.fc .fc-view-harness {
  --tw-bg-opacity: 1;
  background-color: rgba(250, 250, 250, var(--tw-bg-opacity))
}

.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 0px
}

.fc .fc-col-header-cell-cushion {
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 600;
  --tw-text-opacity: 1;
  color: rgba(39, 39, 42, var(--tw-text-opacity))
}

.fc .fc-timegrid-col.fc-day-today {
  --tw-bg-opacity: 1;
  background-color: rgba(238, 242, 255, var(--tw-bg-opacity))
}

.fc-v-event {
  font-weight: 500;
  background-color: #9CA3AF;
  border-color: #9CA3AF
}

.fc .fc-highlight {
  --tw-bg-opacity: 1;
  background-color: rgba(238, 242, 255, var(--tw-bg-opacity))
}

.DayPickerInput-Overlay {
  margin-top: 0.5rem !important;
  border-radius: 0.25rem !important;
  border-width: 1px !important;
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important
}

.DayPickerInput {
  width: 100%
}

.DayPicker-Day {
  border-radius: 0.5rem !important
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(79, 70, 229, var(--tw-bg-opacity)) !important
}

.DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):not(.DayPicker-Day--selected):hover {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(238, 242, 255, var(--tw-bg-opacity)) !important
}

