.fc {
  @apply h-full;
}

.fc .fc-view-harness {
  @apply bg-gray-50;
}

.fc .fc-toolbar.fc-header-toolbar {
  @apply mb-0;
}

.fc .fc-col-header-cell-cushion {
  @apply text-sm font-semibold text-gray-800;
}

.fc .fc-timegrid-col.fc-day-today {
  @apply bg-indigo-50;
}

.fc-v-event {
  @apply font-medium;
  background-color: #9CA3AF;
  border-color: #9CA3AF;
}

.fc .fc-highlight {
  @apply bg-indigo-50;
}

.DayPickerInput-Overlay {
  @apply mt-2 shadow-lg border rounded !important;
}

.DayPickerInput {
  @apply w-full;
}

.DayPicker-Day {
  @apply rounded-lg !important;
}

.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  @apply bg-indigo-600 !important;
}

.DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):not(.DayPicker-Day--selected):hover {
  @apply bg-indigo-50 !important;
}
